/* imports and utilities like animations and mixins should be defined here */
@import './app-variables';
@import '~@xpo-ltl/ngx-ltl-core/styles/reset';
@import './table';

.app {
  &[class*='--hasActionBar'] {
    margin-bottom: $app-actionBar-height + 1;

    .app-ActionBar {
      margin-bottom: -$app-actionBar-height;
    }
  }
}

.xpo-GridSettings-popoverContent {
  max-height: 55vh;
  max-width: 55vw;
  overflow: auto;

  .xpo-GridColumnSelection-columns {
    .mat-checkbox {
      margin-left: 0;
      margin-right: 0;
      max-width: 33%;
      padding-right: 10px;

      .mat-checkbox-layout {
        max-width: 100%;
      }

      .mat-checkbox-label {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
    }
  }
}

.xpo-AgGrid {
  .ag-set-filter {
    display: flex;
    flex-direction: column;
    max-height: 250px;

    .ag-filter-no-matches,
    .ag-set-filter-list {
      height: auto;
      overflow: auto;
      width: 250px;

      .ag-set-filter-item-checkbox,
      .ag-labeled
      .ag-label-align-right
      .ag-checkbox,
      .ag-input-field {
        width: 100%;

        .ag-input-field-label,
        .ag-label
        .ag-checkbox-label {
          overflow: hidden;
          text-overflow: ellipsis;
          width: 90%;
        }
      }
    }
  }
}

