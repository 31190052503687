xpo-ag-grid {
  .ag-full-width-container {
    .ag-full-width-row,
    .ag-full-width-row.ag-row-last {
      border-bottom: none;
    }

    .ag-status-bar {
      display: none;
    }
  }

  .ag-details-row {
    background: #FFF;

    .ag-header {
      display: none;
    }
  }
}
